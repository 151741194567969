import React, { useEffect } from "react";
import "../../styles/filmfestival.scss";
import { navigate } from "gatsby";
import routes from "../../components/constants/routes";

export default function FilmFestival() {
  useEffect(() => {
    navigate(routes.FFAbout);
  }, []);
  return <></>;
}
